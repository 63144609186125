import Layout from "../components/Layout";
import GuidelinesAccordeon from "../components/GuidelinesAccordeon";
import AdditionalInformations from "../components/AdditionalInformations";
import TabDidactic from "../components/TabsDidactic";
import DidacticGuidelineSvg from "../components/DidacticGuidelinesContent/DidacticGuidelineSvg";
import DidacticGuidelineSvgModule1 from "../components/DidacticGuidelinesContent/DidacticGuidelineSvgModule1";
import DidacticGuidelineSvgModule2 from "../components/DidacticGuidelinesContent/DidacticGuidelineSvgModule2";
import DidacticGuidelineResources from "../components/DidacticGuidelineResources";
import Categories from "../components/Categories";
import CategoriesKnowDim from "../components/CategoriesKnowDim";
import TextHighlight2 from "../components/TextHighlight2";
import { useEffect, useState } from "react";
import DidacticGuidelineSvgMobile from "../components/DidacticGuidelinesContent/DidacticGuidelineSvgMobile";
import DidacticGuidelineSvgModule2Mobile from "../components/DidacticGuidelinesContent/DidacticGuidelineSvgModule2Mobile";
import DidacticGuidelineSvgModule1Mobile from "../components/DidacticGuidelinesContent/DidacticGuidelineSvgModule1Mobile";
//import TextIncircle from "../components/TextIncirle";
import TextHighlight3 from "../components/TextHighlight3";
import TextHighlight4 from "../components/TextHighlight4";


const DidacticGuidelines = () => {
  const isMobile = useCheckMobileScreen();

  return (
    <Layout>
      <h1 className="mt-[33px]">The Guidelines</h1>
      <h2 className="mt-[10px]">
        The didactic guidelines are structured to provide teachers with{" "}
        <i>Educational Objectives</i>{" "}
        <AdditionalInformations
          small={isMobile}
          information="describe the knowledge and skills that students are intended to acquire"
        />
        , <i>Instructional Activities</i>{" "}
        <AdditionalInformations
          small={isMobile}
          information=" illustrate tasks and exercises teachers must prepare and conduct during the module to facilitate student learning"
        />{" "}
        and <i>Recommendations</i>{" "}
        <AdditionalInformations
          small={isMobile}
          information="suggest the students' working modalities (individual or group) and details to carry the activity on"
        />{" "}
        for the autonomous implementation of teaching modules on AI in design courses.
      </h2>
      <div className="w-full p-[30px] flex justify-center items-center rounded-xl">
        {isMobile ? <DidacticGuidelineSvgMobile /> : <DidacticGuidelineSvg />}
      </div>
      <TabDidactic
        tabs={[
          {
            title: "Didactic Guidelines",
            mobileTitle: "Didactic...",
            elements: (
              <>
                <div className="flex flex-col gap-2">
                  <div className="bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white px-4 py-6 rounded-b-2xl md:rounded-tr-2xl">
                    <div className="flex flex-col md:flex-row justify-between gap-[14px] mt-[33px]">
                      <p className="flex-1">
                        The didactic guidelines are based on the following
                        modular infrastructure which is composed of two main
                        modules and their relative sub-modules. The first module
                        (“Getting Familiar”) combines the disciplines of Machine
                        Learning, Generative Artificial Intelligence, and Data
                        Visualization and it is intended to provide basic
                        literacy, guided tutorials, and hands-on experiences.
                      </p>
                      <p className="flex-1">
                        The second module supports students in selecting and
                        employing AI, ML, and CV tools for the design process,
                        addressing a defined project brief to foster the
                        acquisition of a method in designing with AI.
                      </p>
                    </div>
                    <a
                      href="/assets/data/files/Download-Didactic-Guidelines/Full-report/DesigningWith-DidacticGuidelines-Full-report.pdf"
                      download="Didactic-Guidelines-Full"
                      className="w-full bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl mt-4 md:mt-[33px] p-[10px]"
                    >
                      <p className="w-full text-center md:text-[50px]">
                        Download the Didactic Guidelines
                      </p>
                    </a>
                  </div>
                  <GuidelinesAccordeon title="Getting Familiar with - Module 1">
                    <h1 className="mt-[33px]">Overall Objective</h1>
                    <h2 className="mt-[10px]">
                      Combining the three sub-modules (ML, GenAI, CV) students
                      will learn to employ AI to train, generate, analyze and
                      visualize data for specific design purposes.
                    </h2>
                    {isMobile ? (
                      <DidacticGuidelineSvgModule1Mobile />
                    ) : (
                      <DidacticGuidelineSvgModule1 />
                    )}
                    <div className="flex flex-col md:flex-row gap-[30px] mt-[33px]">
                      <p className=" md:w-1/2">
                        To promote the development of <i>personal skills</i>,
                        students are suggested to <i>work individually</i>.
                        Teachers can arrange the duration of each activity based
                        on the level of in-depth study required {"["}
                        <a
                          className="underline inline"
                          rel="noreferrer"
                          href="https://www.notion.so/master-interaction-design/Schedule-Example-Download-1ca7fe70e0f042c4adfefef0242abf4b?pvs=4" 
                          target="_blank"
                        >example</a>{"]"}. Sub-modules are not strictly related to
                        each other.
                      </p>
                      <p className=" md:w-1/2">
                        The application of all of them is not mandatory, teachers 
                        can make a selection based on needs and interests. The 
                        structure of the sub-modules is not designed based on 
                        the tool mentioned in the guideline. For a more complete 
                        list of tools, refer to the Designing With framework {"["}
                        <a
                          className="underline inline"
                          rel="noreferrer"
                          href="https://designingwithai.ch/toolsviz/" 
                          target="_blank"
                        >link</a>{"]"}.
                      </p>
                    </div>
                    <a
                      href="/assets/data/files/Download-Didactic-Guidelines/DesigningWith-Module1-GettingFamiliarWith/DesigningWith-Module1-GettingFamiliarWith.pdf"
                      download="Didactic-Guidelines-Module1"
                      className="md:w-1/2 hidden md:flex bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] mb-[33px] rounded-2xl md:mt-[33px]"
                    >
                      <p className="w-full md:text-[29px] p-4">
                        Download the Didactic Guidelines - Module 1
                      </p>
                    </a>
                    <div className="flex flex-col gap-2">
                      <GuidelinesAccordeon title="Getting Familiar with Machine Learning (ML) – Sub-module 1">
                        <h1 className="mt-[33px]">Educational Objective</h1>
                        <h2 className="mt-[10px] flex-1">
                          <TextHighlight2>Students will learn</TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#75D668] bg-opacity-50 shadow-[#75D668]" >
                            {" "}
                            train simplified ML models
                          </TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">
                            create design artefacts
                          </TextHighlight2>
                          {" "}[ML-Obj].
                        </h2>
                        <div className=" w-full flex flex-col md:flex-row gap-[30px] mt-[33px]">
                          <p className=" md:w-1/2">
                            Students develop procedural knowledge through
                            practical guided activities (e.g. RunwayML
                            step-by-step tool tutorials)
                          </p>
                          <p className=" md:w-1/2">
                            and they are asked to apply the acquired procedure
                            to a familiar task (e.g. creating a logo).
                          </p>
                        </div>
                        <h1 className="mt-[33px]">Instructional Activities:</h1>
                        <div className=" flex flex-col md:flex-row gap-[30px] mt-[10px]">
                          <p className=" md:w-1/2">
                            Students are first provided with a basic literacy of
                            ML including technical vocabulary{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with vocabulary knowledge of ML"
                              }
                            >
                              ML-Act1
                            </AdditionalInformations>{" "}
                            and primary functionalities{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a basic functionality of ML"
                              }
                            >
                              ML-Act2
                            </AdditionalInformations>
                            . This activity is meant to promote factual and conceptual 
                            knowledge by teaching the basic elements of the discipline 
                            and their interrelationship to explain the functionality of 
                            ML tools. Once basic literacy is introduced, specific case 
                            studies are provided to contextualise the application of ML 
                            within the design practice{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with practical examples of machine learning applied to design"
                              }
                            >
                              ML-Act3
                            </AdditionalInformations>
                            . At this point, the teaching moves from a theoretical to a
                            procedural level. In this direction, students are first introduced
                            to a guided tutorial{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide a step-by-step tool tutorial, such as RunwayML"
                              }
                            >
                              ML-Act4.3
                            </AdditionalInformations>{" "}
                            on how to use an ML tool (e.g. runway)
                            starting from setting a design goal{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to set or aligning with a design goal"
                              }
                            >
                              ML-Act4.1
                            </AdditionalInformations>{" "}
                            and creating their dataset{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to introduce students in familiarising, collecting or creating data"
                              }
                            >
                              ML-Act4.2
                            </AdditionalInformations>{" "}
                            .
                          </p>
                          <p className=" md:w-1/2">
                          Then, to familiarise themselves with the procedure, 
                          students individually apply what they learned by 
                          training an ML model aligned with the design goal
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to allow students to individually train a simplified machine learning model for a design goal"
                              }
                            >
                              ML-Act5
                            </AdditionalInformations>
                            . Lastly, moving the focus to meta-cognitive knowledge, 
                            students are asked to analyse and document the process 
                            by breaking down the steps and selecting the relevant results{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a structured template for process and results documentation"
                              }
                            >
                              ML-Act6
                            </AdditionalInformations>
                            . This activity aims to create awareness and knowledge 
                            of cognition. To foster participation and sharing of results, 
                            students are asked to present their work and question others{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to make students explaining and questioning the process"
                              }
                            >
                              ML-Act7
                            </AdditionalInformations>
                            .
                          </p>
                        </div>
                        <a
                          href="/assets/data/files/Download-Didactic-Guidelines/DesingingWith-Submodule1-GettingFamiliarWithML/DesingingWith-Submodule1-GettingFamiliarWithML.pdf"
                          download="Didactic-Guidelines-Submodule1-ML"
                          className="w-1/2 hidden md:flex bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl mt-[33px]"
                        >
                          <p className="w-full md:text-[28px] p-4">
                            Download the Didactic Guidelines - Sub-module 1
                          </p>
                        </a>
                      </GuidelinesAccordeon>
                      <GuidelinesAccordeon title="Getting Familiar with Generative AI (GenAI) – Sub-module 2">
                        <h1 className="mt-[33px]">Educational Objective</h1>
                        <h2 className="mt-[10px]">
                          <TextHighlight2>Students will learn</TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#75D668] bg-opacity-50 shadow-[#75D668]" >
                            produce content with GenAI tools
                          </TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">
                            create design artefacts
                          </TextHighlight2>{" "}
                          [GenAI-Obj].
                        </h2>
                        <div className=" w-full flex flex-col md:flex-row gap-[30px] mt-[33px]">
                          <p className=" md:w-1/2">
                            Students develop procedural knowledge through
                            practical guided activities (e.g. Midjourney
                            step-by-step tool tutorials) and they are asked
                          </p>
                          <p className=" md:w-1/2">
                            to apply the acquired procedure to a familiar task
                            (e.g. creating an original shape for a lamp inspired
                            by nature).
                          </p>
                        </div>
                        <h1 className="mt-[33px]">Instructional Activities:</h1>
                        <div className=" flex flex-col md:flex-row gap-[30px] mt-[10px]">
                          <p className=" md:w-1/2">
                            Students are first provided with a basic literacy of
                            AI including technical vocabulary{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with vocabulary knowledge of GenAI"
                              }
                            >
                              GenAI-Act1
                            </AdditionalInformations>{" "}
                            and primary functionalities{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a basic functionality of GenAI"
                              }
                            >
                              GenAI-Act2
                            </AdditionalInformations>
                            . This activity is meant to promote factual and
                            conceptual knowledge by teaching the basic elements
                            of the discipline and their interrelationship to
                            explain the functionality of AI tools. Once basic
                            literacy is introduced, specific case studies are
                            provided to contextualise the application of AI
                            within the design practice{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with practical examples of GenAI applied to design"
                              }
                            >
                              GenAI-Act3
                            </AdditionalInformations>
                            . At this point, the teaching moves from a
                            theoretical to a procedural level. In this
                            direction, students are first introduced to a guided
                            tutorial{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide a step-by-step tool tutorial, such as Midjourney, Dall-E"
                              }
                            >
                              GenAI-Act4.3
                            </AdditionalInformations>{" "}
                            on how to use a Generative AI tool (e.g. Midjourney)
                            starting from setting a design goal{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to set or aligning with a design goal"
                              }
                            >
                              GenAI-Act4.1
                            </AdditionalInformations>{" "}
                            and experimenting with relative prompts
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to introduce students in familiarising, collecting or creating prompts(data)"
                              }
                            >
                              GenAI-Act4.2
                            </AdditionalInformations>
                            .
                          </p>
                          <p className=" md:w-1/2">
                            Then, to familiarise themselves with the
                            procedure, students individually apply what they
                            learned by refining selected prompts and generating
                            content based on the design goal{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to allow students to individually generate contents with GenAI for a design goal"
                              }
                            >
                              GenAI-Act5
                            </AdditionalInformations>
                            . Lastly, moving the focus to meta-cognitive
                            knowledge, students are asked to analyse and
                            document the process by breaking down the steps and
                            selecting the relevant results{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a structured template for process and results documentation"
                              }
                            >
                              GenAI-Act6
                            </AdditionalInformations>
                            . This activity aims to create awareness and
                            knowledge of cognition. To foster participation and
                            sharing of results, students are asked to present
                            their work and question others{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to make students explaining and questioning the process"
                              }
                            >
                              GenAI-Act7
                            </AdditionalInformations>
                            .
                          </p>
                        </div>
                        <a
                          href="/assets/data/files/Download-Didactic-Guidelines/DesingingWith-Submodule1-GettingFamiliarWithGenAI/DesigningWith-Module1-GettingFamiliarWithGenAI.pdf"
                          download="Didactic-Guidelines-Submodule2-GenAI"
                          className="w-1/2 hidden md:flex bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl mt-[33px]"
                        >
                          <p className="w-full md:text-[28px] p-4">
                            Download the Didactic Guidelines - Sub-module 2
                          </p>
                        </a>
                      </GuidelinesAccordeon>
                      <GuidelinesAccordeon title="Getting Familiar with Data Visualization (DV) – Sub-module 3">
                        <h1 className="mt-[40px]">Educational Objective</h1>
                        <h2 className="mt-[15px]">
                          <TextHighlight2>Students will learn</TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#75D668] bg-opacity-50 shadow-[#75D668]" >
                            apply Computer Vision (CV) algorithms
                          </TextHighlight2>{" "}
                          to{" "}
                          <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">
                            analyse and visualise data
                          </TextHighlight2>{" "}
                          [CV-Obj].
                        </h2>
                        <div className=" w-full flex flex-col md:flex-row gap-[30px] mt-[33px]">
                          <p className=" md:w-1/2">
                            Students develop procedural knowledge through
                            practical guided activities (e.g. Midjourney
                            step-by-step tool tutorials)
                          </p>
                          <p className=" md:w-1/2">
                            and they are asked to apply the acquired procedure
                            to a familiar task (e.g. creating an infographic).
                          </p>
                        </div>
                        <h1 className="mt-[33px]">Instructional Activities:</h1>
                        <div className=" flex flex-col md:flex-row gap-[30px] mt-[10px]">
                          <p className=" md:w-1/2">
                            Students are first provided with a basic literacy of
                            CV algorithms and data visualisation (CV) techniques
                            including technical vocabulary{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with vocabulary knowledge of Computer Vision"
                              }
                            >
                              CV-Act1
                            </AdditionalInformations>{" "}
                            and primary functionalities{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a basic functionality of Computer Vision Algorithms and Data Visualization technniques"
                              }
                            >
                              CV-Act2
                            </AdditionalInformations>
                            . This activity is meant to promote factual and
                            conceptual knowledge by teaching the basic elements
                            of the disciplines (CV and CV) and their
                            interrelationship in analysing and visualising
                            complex phenomena (Omena, 2021). Once basic literacy
                            is introduced, specific case studies are provided to
                            contextualise the application of CV within the
                            design research{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with practical examples of computer vision applied to design research"
                              }
                            >
                              CV-Act3
                            </AdditionalInformations>
                            . At this point, the teaching moves from a
                            theoretical to a procedural level. In this
                            direction, students are first introduced to a guided
                            tutorial{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide a step-by-step tool tutorial, such as Memespecotor, Gephi"
                              }
                            >
                              CV-Act4.3
                            </AdditionalInformations>{" "}
                            on how to analyse data with CV algorithms (e.g. Meme
                            Spector) and visualise the results (e.g. Gephi)
                            starting from setting a research question
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to introduce students in familiarising, collecting or creating data"
                              }
                            >
                              CV-Act4.1
                            </AdditionalInformations>{" "}
                            and collecting and organising data related to the
                            phenomena{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to set or aligning with a research question"
                              }
                            >
                              CV-Act4.2
                            </AdditionalInformations>
                            . Then, to familiarise themselves with the
                            procedure, students individually apply what they
                            learned by investigating the dataset to answer the
                            research question{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to allow students to individually analyzes data with computer vision for a research goal"
                              }
                            >
                              CV-Act5
                            </AdditionalInformations>
                            .
                          </p>
                          <p className=" md:w-1/2">
                            Lastly, moving the focus to meta-cognitive
                            knowledge, students are asked to analyse and
                            document the process by breaking down the steps and
                            selecting the relevant results{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to provide students with a structured template for process and results documentation"
                              }
                            >
                              CV-Act6
                            </AdditionalInformations>
                            . This activity aims to create awareness and
                            knowledge of cognition. To foster participation and
                            sharing of results, students are asked to present
                            their work and question others{" "}
                            <AdditionalInformations
                              small={true}
                              information={
                                "activities intended to make students explaining and questioning the process"
                              }
                            >
                              CV-Act7
                            </AdditionalInformations>
                            .
                          </p>
                        </div>
                        <a
                          href="/assets/data/files/Download-Didactic-Guidelines/DesingingWith-Submodule1-GettingFamiliarWithDV/DesingingWith-Submodule1-GettingFamiliarWithDV.pdf"
                          download="Didactic-Guidelines-Submodule3-CV"
                          className="w-1/2 hidden md:flex bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl mt-[33px]"
                        >
                          <p className="w-full md:text-[28px] p-4">
                            Download the Didactic Guidelines - Sub-module 3
                          </p>
                        </a>
                      </GuidelinesAccordeon>
                    </div>
                  </GuidelinesAccordeon>
                  <GuidelinesAccordeon title="Get in Depth – Module 2">
                    <h1 className="mt-[33px]">Educational Objective</h1>
                    <h2 className="mt-[10px]">
                      <TextHighlight2>Students will learn</TextHighlight2> to{" "}
                      <TextHighlight2 color="bg-[#75D668] bg-opacity-50 shadow-[#75D668]">
                        choose and apply AI/ML/CV tools
                      </TextHighlight2>{" "}
                      for <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">specific activities</TextHighlight2>{" "}
                      of the <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">design process</TextHighlight2>
                      {" "}[Module 2-Obj].
                    </h2>
                    {isMobile ? (
                      <DidacticGuidelineSvgModule2Mobile />
                    ) : (
                      <DidacticGuidelineSvgModule2 />
                    )}
                    <div className="flex flex-col md:flex-row gap-[30px] mt-[70px]">
                      <p className=" md:w-1/2">
                        To face this module, students need some basic knowledge
                        of the disciplines involved and some practice of tools.
                        For this reason, the selection of tools provided to
                        students within this module depends on the skills
                        acquired during the first module or prior knowledge.
                      </p>
                      <p className=" md:w-1/2">
                        To define the set of tools suitable, refer to the
                        DesigningW/ framework. Working in groups (5-6 people) is
                        recommended for this module, as collaboration
                        facilitates addressing complex challenges. Additionally,
                        a multidisciplinary approach incentivises different
                        perspectives to emerge.
                      </p>
                    </div>
                    <h1 className="mt-[33px]">Instructional Activities:</h1>
                    <div className="flex flex-col md:flex-row gap-[30px] mt-[10px]">
                      <p className=" md:w-1/2">
                        Students are first provided with a design challenge to
                        drive the activity{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to provide students with a design challenge"
                          }
                        >
                          Module2-Act1
                        </AdditionalInformations>
                        . The design challenge provides a macro area for
                        students to experiment with tools based on specific
                        research questions and tasks. Examples of these
                        challenges are designing for climate change and
                        designing for twin cities. A detailed description is
                        available from the Designing With Workshop repository [
                        <a
                          className="underline inline"
                          rel="noreferrer"
                          href="https://www.notion.so/master-interaction-design/PROJECTS-BRIEF-0924a285052d407c88de38ca3d2d8aba?pvs=4" 
                          target="_blank"
                        >example</a>]. To help students understand the challenge,
                        case studies are provided. Working on challenges
                        contributes to developing a knowledge dimension as
                        students are asked to understand the interrelationship
                        of basic elements (acquired in Module 1) and make them
                        function together within a larger structure. Once the
                        context is set, tools are introduced through the
                        Designing With Framework
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to support students in understanding, exploring and using the Framework (from a practical-application point of view, which implies that the literacy phase ref. module1 first occurs)"
                          }
                        >
                          Module2-Act2
                        </AdditionalInformations>
                        . The framework aims to introduce students to exploring
                        tools of AI/ML/CV, understanding their capabilities, and
                        applying them within a design process. Additionally, the
                        framework promotes a data-based approach, bringing
                        students closer to a design practice led by data input
                        and output. The data-based approach is useful,
                        especially in promoting the method of inquiry, which is
                        part of the procedural knowledge dimension developed
                        within this activity. Fostering the exploration of tools
                        through the framework aims to infuse in students a
                        method of design with AI.
                      </p>
                      <p className=" md:w-1/2">
                        During the process, students are asked to document this
                        method. For this purpose, a structured template [
                        <a
                          className="underline inline"
                          rel="noreferrer"
                          href="https://www.notion.so/master-interaction-design/Project-Title-Template-Example-259f57dd5d104ea1a5b3844134a219eb?pvs=4" 
                          target="_blank"
                        >example</a>] is introduced{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to provide students with a structured template for the process and results documentation"
                          }
                        >
                          Module2-Act3
                        </AdditionalInformations>
                        . While in Module 1 documentation is one of the final
                        activities, for the second module, keeping track of the
                        steps, tools and choices at each of the design phases is
                        encouraged to foster the acquisition of the method.
                        During these activities, supervision from tutors and
                        experts in the field is useful for choosing{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to supervise students in choosing AI tools for specific design phases (rev.1)"
                          }
                        >
                          Module2-Act4
                        </AdditionalInformations>{" "}
                        and properly applying AI tools
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to supervise students in applying AI tools for specific design phases (rev.2)"
                          }
                        >
                          Module2-Act5
                        </AdditionalInformations>{" "}
                        for specific tasks defined based on the initial
                        challenge. Two rounds of revisions are planned: the
                        first one{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to supervise students in choosing AI tools for specific design phases (rev.1)"
                          }
                        >
                          Module2-Act4
                        </AdditionalInformations>{" "}
                        aims to support students in choosing the appropriate
                        tools and refining the design concept; while the second
                        one{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to supervise students in applying AI tools for specific design phases (rev.2)"
                          }
                        >
                          Module2-Act5
                        </AdditionalInformations>{" "}
                        focuses on providing technical support in using the
                        tools, as this second revision comes later when students
                        are supposed to have the concept already defined and
                        move to the prototyping and implementing phase. To allow
                        teachers to assess the activities and future
                        implementation of the module, students are asked for
                        feedback.{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to ask students for feedback on the teaching module"
                          }
                        >
                          Module2-Act6
                        </AdditionalInformations>{" "}
                        This activity contributes to creating awareness and
                        knowledge of cognition, improving the meta-cognitive
                        knowledge of students. Lastly, to foster participation
                        and sharing of results, students are asked to present
                        their work and question others{" "}
                        <AdditionalInformations
                          small={true}
                          information={
                            "activities intended to make students explaining and questioning the process and results"
                          }
                        >
                          Module2-Act7
                        </AdditionalInformations>
                        .
                      </p>
                    </div>
                    <a
                      href="/assets/data/files/Download-Didactic-Guidelines/DesigningWith-Module2-GethInDepth"
                      download="Didactic-Guidelines-Module2"
                      className="w-1/2 hidden md:flex bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_35px_20px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl mt-[33px]"
                    >
                      <p className="w-full md:text-[29px] p-4">
                        Download the Didactic Guidelines - Module 2
                      </p>
                    </a>
                  </GuidelinesAccordeon>
                </div>
              </>
            ),
          },
          {
            title: "About didactic guidelines",
            mobileTitle: "About...",
            elements: (
              <>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-b-2xl md:rounded-tr-2xl md:flex-row justify-between px-4 py-6 gap-[14px]">
                    <p className="flex-1 mt-[33px] mb-[25px]">
                      To build the didactic guidelines aimed to systematically
                      integrate AI tools into design curricula, we referred to
                      Bloom’s Taxonomy, which is one of the most recognized and
                      widely used frameworks in the field of education. More
                      specifically we considered the Anderson & Krathwohl (2001)
                      model revised for 21st-Century learners.
                    </p>
                    <p className="flex-1 mt-[33px] mb-[25px]">
                      The following sections explain the specific components of
                      the taxonomy (Krathwohl and Payne, 1971) and illustrate
                      how these components are combined together to build the
                      didactic guidelines.
                    </p>
                  </div>
                  <h2 className="bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_30px_30px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl px-4 py-6 ">
                    1.1 Categories of Knowledge Dimensions
                  </h2>
                  <div className="flex flex-col md:flex-row px-4 py-6 bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-2xl gap-[14px]">
                    <div className="flex flex-col md:w-1/2">
                      <p className=" md:text-[29px]">
                        Knowledge can be classified in four dimensions: 
                      </p>
                      <br />
                      <br />
                      <p className=" md:text-[29px]">
                        (a) Factual knowledge refers to terminology and specific details; 
                      </p>
                      <p className=" md:text-[29px]">
                        (b) Conceptual knowledge, refers to classification, categories, principles and theories;{" "}
                      </p>
                      <p className=" md:text-[29px]">
                        (c) Procedural Knowledge, refers to skills algorithms, techniques and methods; 
                      </p>
                      <p className=" md:text-[29px]">
                        (d) Metacognitive Knowledge, refers to a cognitive task, strategies and self-awareness.{" "}
                      </p>
                    </div>
                    <div className=" md:w-1/2 flex flex-col">
                      <div className="flex flex-row pl-4">
                        <p className="md:w-1/2 text-[12px] md:text-[16px]">
                          Major types and subtypes
                        </p>
                        <p className="hidden md:flex w-1/2 text-[16px]">
                          Examples
                        </p>
                      </div>
                      <div className="flex flex-col gap-4 mt-4">
                        <Categories
                          title="(a) Factual Knowledge "
                          majTypes1="(a.a) Knowledge of terminology"
                          majTypes2="(a.b) Knowledge of specific details and elements"
                          majTypes3=""
                          ex1="Technical vocabulary, music symbols"
                          ex2="Major natural resources, reliable sources of informations"
                          ex3=""
                        />
                        <Categories
                          title="(b) Conceptual Knowledge  "
                          majTypes1="(b.a) Knowledge of classifications and categories"
                          majTypes2="(b.b) Knowledge of principles and generalizations"
                          majTypes3="(b.c) Knowledge of theories, models, and structures"
                          ex1="Periods of geological time, forms of business ownership"
                          ex2="Pythagorean theorem, law of supply and demand"
                          ex3="Theory of evolution, structure of Congress"
                        />
                        <Categories
                          title="(c) Procedural Knowledge "
                          majTypes1="(c.a) Knowledge of subject-specific skills and algorithms"
                          majTypes2="(c.b) Knowledge of subject-specific techniques and methods"
                          majTypes3="(c.c) Knowledge of criteria for determining when to use appropriate procedures"
                          ex1="Skills used in painting with water colors, whole-number division algorithm"
                          ex2="Interviewing techniques, scientific method"
                          ex3="Criteria used to determine when to apply a procedure involving Newton’s second law, criteria used to judge the feasibility of using a particular method to estimate business costs"
                        />
                        <Categories
                          title="(d) Metacognitive Knowledge "
                          majTypes1="(d.a) Strategic knowledge"
                          majTypes2="(d.b) Knowledge about cognitive tasks, including appropriate contextual and conditional knowledge"
                          majTypes3="(d.c) Self-knowledge"
                          ex1="Knowledge of outlining as a means of capturing the structure of a unit of subject matter in a text book, knowledge of the use of heuristics"
                          ex2="Knowledge of the types of tests particular teachers administer, knowledge of the cognitive demands of different tasks"
                          ex3="Knowledge that critiquing essays is a personal strength, whereas writing essays is a personal weakness; awareness of one’s own knowledge level"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_30px_30px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl px-4 py-6 ">
                    1.2 Categories of Cognitive Processes
                  </h2>
                  <div className="flex flex-col md:flex-row px-4 py-6 bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-2xl gap-[14px]">
                    <div className="flex flex-col md:w-1/2">
                      <p className=" md:text-[29px]">
                        The cognitive process dimensions comprise six major categories, 
                        each associated with specific cognitive processes, totalling 19. 
                      </p>
                      <br />
                      <br />
                      <p className=" md:text-[29px]">
                        These categories, arranged in increasing order of
                        complexity, are:  
                      </p>
                      <p className=" md:text-[29px]">
                        (1) Remember: involves retrieving knowledge from long-term memory. 
                        Specific processes include recognizing and recalling.
                      </p>
                      <p className=" md:text-[29px]">
                        (2) Understand: It entails constructing meaning from
                        instructional messages (oral, written, graphic). It
                        encompasses interpreting, exemplifying, classifying,
                        summarizing, inferring, comparing, and explaining.
                      </p>
                      <p className=" md:text-[29px]">
                        (3) Apply: About carrying out or using a procedure in a
                        given situation. This includes executing and
                        implementing.
                      </p>
                      <p className=" md:text-[29px]">
                        (4) Analyze: It involves breaking material into parts
                        and understanding their interrelations and the overall
                        structure or purpose. It includes differentiating,
                        organizing and attributing.{" "}
                      </p>
                      <p className=" md:text-[29px]">
                        (5) Evaluate: It concerns making judgments based on
                        criteria and standards. It encompasses checking and
                        critiquing. {" "}
                      </p>
                      <p className=" md:text-[29px]">
                        (6) Create: It entails combining or reorganizing
                        elements to form a novel, coherent, original or
                        functional whole. It involves generating, planning and
                        producing.{" "}
                      </p>
                    </div>
                    <div className=" md:w-1/2 flex flex-col">
                      <div className="flex flex-col pl-4 md:flex-row">
                        <p className="md:w-1/4 text-[12px] md:text-[16px]">
                          Process categories
                        </p>
                        <p className="md:w-3/4 text-[12px] md:text-[16px]">
                          Cognitive processes and examples
                        </p>
                      </div>
                      <div className="flex flex-col w-full gap-4 mt-4">
                        <CategoriesKnowDim
                          title="(1) Remember"
                          majTypes1="1.1 Recognizing"
                          majTypes2="1.2 Recalling"
                          majTypes3=""
                          majTypes4=""
                          majTypes5=""
                          majTypes6=""
                          majTypes7=""
                          ex1="(e.g., Recognize the dates of important events in U.S. history)"
                          ex2="(e.g., Recall the dates of important events in U.S. history)"
                          ex3=""
                          ex4=""
                          ex5=""
                          ex6=""
                          ex7=""
                        />
                        <CategoriesKnowDim
                          title="(2) Understand"
                          majTypes1="2.1 Interpreting"
                          majTypes2="2.2 Exemplifying"
                          majTypes3="2.3 Classifying"
                          majTypes4="2.4 Summarizing"
                          majTypes5="2.5 Inferring"
                          majTypes6="2.6 Comparing"
                          majTypes7="2.7 Explaining"
                          ex1="(e.g., Paraphrase important speeches and documents)"
                          ex2="(e.g., Give examples of various artistic painting styles)"
                          ex3="(e.g., Classify observed or described cases of mental disorders)"
                          ex4="(e.g., Write a short summary of the events portrayed on videotapes)"
                          ex5="(e.g., In learning a foreign language, infer gramrnatical principles from examples)"
                          ex6="(e.g., Compare historical events to contemporary situations)"
                          ex7="(e.g., Explain the causes of important eighteenth-century events in France)"
                        />
                        <CategoriesKnowDim
                          title="(3) Apply"
                          majTypes1="3.1 Executing"
                          majTypes2="3.2 Implementing"
                          majTypes3=""
                          majTypes4=""
                          majTypes5=""
                          majTypes6=""
                          majTypes7=""
                          ex2="(e.g., Determine in which situations Newton's second law is appropriate)"
                          ex1="(e.g., Divide one whole number by another whole number, both with multiple digits)"
                          ex3=""
                          ex4=""
                          ex5=""
                          ex6=""
                          ex7=""
                        />
                        <CategoriesKnowDim
                          title="(4) Analyze"
                          majTypes1="4.1 Differentiating"
                          majTypes2="4.2 Organnizing"
                          majTypes3="4.3 Attributing"
                          majTypes4=""
                          majTypes5=""
                          majTypes6=""
                          majTypes7=""
                          ex1="(e.g., Distinguish between relevant and irrelevant numbers in a mathematical word problem)"
                          ex2="(e.g., Structure evidence in a historical description into evidence for and against a particular historical explanation)"
                          ex3="(e.g., Determine the point of view of the author of an essay in terms of his or her political perspective)"
                          ex4=""
                          ex5=""
                          ex6=""
                          ex7=""
                        />
                        <CategoriesKnowDim
                          title="(5) Evaluate"
                          majTypes1="5.1 Checking"
                          majTypes2="5.2 Critiquing"
                          majTypes3=""
                          majTypes4=""
                          majTypes5=""
                          majTypes6=""
                          majTypes7=""
                          ex1="Knowledge of outlining as a means of capturing the structure of a unit of subject matter in a text book, knowledge of the use of heuristics"
                          ex2="Knowledge of the types of tests particular teachers administer, knowledge of the cognitive demands of different tasks"
                          ex3="Knowledge that critiquing essays is a personal strength, whereas writing essays is a personal weakness; awareness of one’s own knowledge level"
                          ex4=""
                          ex5=""
                          ex6=""
                          ex7=""
                        />
                        <CategoriesKnowDim
                          title="(6) Create"
                          majTypes1="6.1 Generating"
                          majTypes2="6.2 Planning"
                          majTypes3="6.3 Producing"
                          majTypes4=""
                          majTypes5=""
                          majTypes6=""
                          majTypes7=""
                          ex1="Knowledge of outlining as a means of capturing the structure of a unit of subject matter in a text book, knowledge of the use of heuristics"
                          ex2="Knowledge of the types of tests particular teachers administer, knowledge of the cognitive demands of different tasks"
                          ex3="Knowledge that critiquing essays is a personal strength, whereas writing essays is a personal weakness; awareness of one’s own knowledge level"
                          ex4=""
                          ex5=""
                          ex6=""
                          ex7=""
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_30px_30px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl px-4 py-6 ">
                    1.3 Towards Educational Objectives and Instructional
                    Activities
                  </h2>
                  <div className="flex flex-col px-4 py-6 bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-2xl md:flex-row gap-[14px]">
                    <div className="flex flex-col md:w-1/2">
                      <p>
                        The cognitive processes and the knowledge dimensions are
                        combined to define the educational objectives and the
                        instructional activities according to the following
                        syntax.
                      </p>
                      <div className=" flex flex-col md:flex-row w-full text-center">
                        <p className="">
                          <TextHighlight4>statement</TextHighlight4>
                        </p>
                        <p>+</p>
                        <p className=" px-5">
                          <TextHighlight4 color="shadow-[#75D668] bg-opacity-50">
                            verb
                          </TextHighlight4>
                        </p>
                        <p>+</p>
                        <p className="">
                          <TextHighlight4 color="shadow-[#73EB14] bg-opacity-60">
                            noun
                          </TextHighlight4>
                        </p>
                      </div>
                      <br />
                      <br />
                      <br />
                      <p className="mb-1">
                        <TextHighlight3 color="bg-[#A1A1A1]">
                          Statement [EO]
                        </TextHighlight3>{" "}
                        “Student will learn to..”
                      </p>
                      <p>
                        <TextHighlight3 color="bg-[#A1A1A1]">
                          Statement [EO]
                        </TextHighlight3>{" "}
                        “Activity intended to..”
                      </p>
                      <br />
                      <p>
                        The verb refers to{" "}
                        <TextHighlight3 color="bg-[#75D668]">
                          cognitive processes
                        </TextHighlight3>
                          (1) Remember; (2) Understand; (3) Apply; (4) Analyze;
                        (5) Evaluate; (6) Create. 
                      </p>
                      <br />
                      <p>
                        Nouns refer to the{" "}
                        <TextHighlight3 color="bg-[#73EB14]">
                          {" "}
                          knowledge dimension{" "}
                        </TextHighlight3>{" "}
                        that students are expected to acquire or build: (a)
                        Factual Knowledge; (b) Conceptual Knowledge; (c)
                        Procedural Knowledge; (d) Metacognitive Knowledge.{" "}
                      </p>
                    </div>

                    <div className="flex flex-col md:w-1/2">
                      <p>Example</p>
                      <br />
                      <p>Educational Objective </p>
                      <h2>
                        <TextHighlight2>Students will learn</TextHighlight2> to{" "}
                      </h2>
                      <h2>
                        <TextHighlight2 color="bg-[#75D668] bg-opacity-50 shadow-[#75D668]">
                          train a Machine Learning model
                        </TextHighlight2>{" "}
                        to{" "}
                      </h2>
                      <h2>
                        <TextHighlight2 color="bg-[#73EB14] bg-opacity-60 shadow-[#73EB14]">
                          create design artefacts
                        </TextHighlight2>
                        .
                      </h2>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.1{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          provide students
                        </TextHighlight3>
                        {" "}with{" "}
                        <TextHighlight3 color="bg-[#73EB14] ">
                          vocabulary knowledge of ML;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.2{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          provide students
                        </TextHighlight3>
                        {" "}with{" "}
                        <TextHighlight3 color="bg-[#73EB14] ">
                          a basic functionality of ML;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.3{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          provide students
                        </TextHighlight3>
                        {" "}with{" "}
                        <TextHighlight3 color="bg-[#73EB14] ">
                          practical examples of machine learning applied to
                          design;
                        </TextHighlight3>{" "}
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.4{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          explain
                        </TextHighlight3>{" "}
                        the{" "}
                        <TextHighlight3 color="bg-[#73EB14] ">
                          procedure of training a simplified machine learning
                          model;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                        <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.4.1{"}"}{" "}</h2>
                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          set or align
                        </TextHighlight3>
                        {" "}with{" "}
                        <TextHighlight3 color="bg-[#73EB14] ">
                          a design goal;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.4.2{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1] ">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668] ">
                          introduce students
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#73EB14]">
                          familiarising, collecting or creating data;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.4.3{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1]">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668]">
                          provide
                        </TextHighlight3>{" "}
                        a{" "}
                        <TextHighlight3 color="bg-[#73EB14]">
                          step-by-step tool tutorial, such as RunwayML;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.5{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1]">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668]">
                          allow students
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#73EB14]">
                          individually train a simplified machine learning model
                          for a design goal;
                        </TextHighlight3>
                      </p>
                      <br />
                      <p className="leading-relaxed">
                      <h2 className="inline text-[16px] md:text-[24px]">{"{"}AI.6{"}"}{" "}</h2>                        <TextHighlight3 color="bg-[#A1A1A1]">
                          Activities intended
                        </TextHighlight3>{" "}
                        to{" "}
                        <TextHighlight3 color="bg-[#75D668]">
                          provide students
                        </TextHighlight3>
                        {" "}with a{" "}
                        <TextHighlight3 color="bg-[#73EB14]">
                          structured template for process and results
                          documentation;
                        </TextHighlight3>
                      </p>
                      <br />
                    </div>
                  </div>
                  <h2 className="flex w-full bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_30px_30px_rgba(1,1,1,0)] shadow-[#8CD782] rounded-2xl px-4 py-6 ">
                    1.4 The framework of Bloom's taxonomy table
                  </h2>
                  <div className="flex flex-col px-4 py-6 bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-2xl w-full gap-4">
                    <div className="flex flex-col gap-4 md:flex-row">
                      <p className=" md:w-1/2">
                        The Bloom Taxonomy proposes a table aimed to formalise
                        and organise Educational Objectives and Instructional
                        Activities according to knowledge dimensions and
                        cognitive processes. The table helps educators identify
                        instructional activities based on the educational goals
                        of the teaching module. 
                      </p>
                      <p className=" md:w-1/2">
                        The table is presented below. The knowledge dimension
                        categories are organised horizontally, while cognitive
                        process dimensions are reported in columns.
                      </p>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="flex flex-row w-full gap-2">
                        <p className=" w-[44.5%] md:h-auto md:w-1/4 rounded-lg text-[16px]  border bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white md:text-[22px] p-2 md:p-4 border-green">
                          The knowledge dimension
                        </p>
                        <div className="flex flex-col w-[55.5%] md:w-3/4 gap-2">
                          <p className=" w-full md:w-full sm:h-full md:h-auto text-[16px] md:text-[22px] rounded-lg border bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 md:p-4 border-green">
                            The cognitive process dimension
                          </p>
                          <div className="hidden md:flex flex-row w-full gap-2">
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (1) Remember
                            </p>
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (2) Understand
                            </p>
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (3) Apply
                            </p>
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (4) Analyze
                            </p>
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (5) Evaluate
                            </p>
                            <p className=" w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white  p-2 border-green">
                              (6) Create
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-2 w-full">
                        <div className="flex flex-col gap-2 w-[44.5%] md:w-full">
                          <div className="flex flex-row w-full gap-2">
                            <p className="w-full md:w-1/4 rounded-lg text-[16px]  border bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white md:text-[22px] p-2 border-green">
                              (a) Factual Knowledge
                            </p>
                            <div className="hidden md:flex w-3/5 md:w-3/4 flex-row gap-2">
                              <p className=" w-1/6 h-full rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[16px] md:text-[22px]  bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border ttext-[16px] md:ext-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-2">
                            <p className="w-full md:w-1/4 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (b) Conceptual Knowledge
                            </p>
                            <div className="hidden md:flex w-3/5 md:w-3/4  flex-row gap-2">
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-2">
                            <p className="w-full md:w-1/4 rounded-lg border text-[16px]  md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (c) Procedural Knowledge
                            </p>
                            <div className="hidden md:flex w-3/5 md:w-3/4  flex-row gap-2">
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-2">
                            <p className="w-full md:w-1/4 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green">
                              (d) Meta Knowledge
                            </p>
                            <div className="hidden md:flex w-3/5 md:w-3/4  flex-row gap-2">
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                              <p className=" w-1/6 h-full rounded-lg border text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-2 border-green"></p>
                            </div>
                          </div>
                        </div>
                        <div className=" flex md:hidden w-[55.5%]">
                          <div className=" flex md:hidden flex-row w-full gap-2">
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className=" bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (1) Remember
                              </div>
                            </div>
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className="bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (2) Understand
                              </div>
                            </div>
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className="bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (3) Apply
                              </div>
                            </div>
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className="bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (4) Analyse
                              </div>
                            </div>
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className="bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (5) Evaluate
                              </div>
                            </div>
                            <div className="relative flex w-1/6 rounded-lg border text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              <div className="bg-transparent absolute bottom-[80px] w-40 -rotate-90 -ml-[75px]">
                                (6) Create
                              </div>
                            </div>
                            {/* <p className=" w-full rounded-lg border -rotate-90 text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              (2) Understand
                            </p>
                            <p className=" w-full rounded-lg border -rotate-90 text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              (3) Apply
                            </p>
                            <p className=" w-full rounded-lg border -rotate-90 text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              (4) Analyze
                            </p>
                            <p className=" w-full rounded-lg border -rotate-90 text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              (5) Evaluate
                            </p>
                            <p className=" w-full rounded-lg border -rotate-90 text-[16px] md:text-[22px] bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white p-1 border-green">
                              (6) Create
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className=" mt-6 md:w-1/2">
                      {" "}
                      Starting from this table, we established the educational
                      objectives and the instructional activities that structure
                      the didactic guidelines. A table for each specific
                      teaching module is available to download from the
                      "Didactic Guidelines" section.
                    </p>
                  </div>
                </div>
              </>
            ),
          },
        ]}
      />
      <p className="mt-10">Didactic Resources</p>
      <DidacticGuidelineResources
        title="Designing With AI + ML + DV - Pilot
        "
        subtitle="16 - 21 Jul 2023, SUPSI, Mendrisio"
        type="Event"
        link="https://pilotworkshop.designingwithai.ch/"
      />
      <DidacticGuidelineResources
        title="Co-designing with AI"
        subtitle="12 - 16 Feb 2024, SUPSI, Mendrisio"
        type="Event"
        link="https://workshop.designingwithai.ch/"
      />
      <DidacticGuidelineResources
        title="Generative AI as research methods
        "
        subtitle="October 2023, NOVA, Lisbon"
        type="Event"
        link="http://dx.doi.org/10.5281/zenodo.10475202"
      />
      <DidacticGuidelineResources
        title="Network Vision Methodology
        "
        subtitle="November 2023, NOVA, Lisbon"
        type="Event"
        link="http://dx.doi.org/10.5281/zenodo.10475202"
      />
      <DidacticGuidelineResources
        title="Icebreaking Plot"
        type="Resources"  
        link="/assets/data/files/Download-Didactic-Guidelines/DesigningWithAI-IcebreakingPlot/DesigningWithAI-IcebreakingPlot.pdf"
        filename="DidacticResource-IcebreakingTool"
      />
      <DidacticGuidelineResources
        title="Six-Stage Design Process Model for Education"
        type="Resources"  
        link="/assets/data/files/Download-Didactic-Guidelines/©_Botta,_M_,_Autuori,_A_,_Subet,_M_,_Terenghi,_G_2024_Six-Stage_Design_Process_Model_for_Education/©_Botta,_M_,_Autuori,_A_,_Subet,_M_,_Terenghi,_G_2024_Six-Stage_Design_Process_Model_for_Education.pdf"
        filename="©_Botta,_M_,_Autuori,_A_,_Subet,_M_,_Terenghi,_G_2024_Six-Stage_Design_Process_Model_for_Education"
      />

      <p className="text-[12px] md:text-[15px] mt-10">
        © 2024. This project is licensed under CC BY 4.0. Supported by Movetia.
        Exchange and mobility.
      </p>
    </Layout>
  );
};

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width < height;
};

export default DidacticGuidelines;
