import React from "react";

interface CategoriesKnowProps {
  title: string;
  majTypes1: string;
  majTypes2: string;
  majTypes3: string;
  majTypes4: string;
  majTypes5: string;
  majTypes6: string;
  majTypes7: string;
  ex1: string;
  ex2: string;
  ex3: string;
  ex4: string;
  ex5: string;
  ex6: string;
  ex7: string;
}

const CategoriesKnowDim: React.FC<CategoriesKnowProps> = (props) => {
  return (
    <div className="flex flex-col gap-4 w-full bg-[#D9D9D9] bg-opacity-50 shadow-[inset_0_0_20px_5px_rgba(1,1,1,0)] shadow-white rounded-lg border p-4 border-green">
      <p className=" md:w-1/4 ">{props.title}</p>
      {props.majTypes1.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes1}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex1}
          </p>
        </div>
      )}
      {props.majTypes2.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes2}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex2}
          </p>
        </div>
      )}
      {props.majTypes3.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes3}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex3}
          </p>
        </div>
      )}
      {props.majTypes4.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes4}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex4}
          </p>
        </div>
      )}
      {props.majTypes5.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes5}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex5}
          </p>
        </div>
      )}
      {props.majTypes6.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes6}
          </p>
          <p className=" w-full md:w-3/4 ttext-[12px] md:text-[16px]">
            {props.ex6}
          </p>
        </div>
      )}
      {props.majTypes7.length > 0 && (
        <div className="flex flex-row gap-4">
          <p className=" w-full md:w-1/4 text-[12px] md:text-[16px]">
            {props.majTypes7}
          </p>
          <p className=" w-full md:w-3/4 text-[12px] md:text-[16px]">
            {props.ex7}
          </p>
        </div>
      )}
    </div>
  );
};

export default CategoriesKnowDim;
